import { default as _91_46_46_46slug_93XC5eIRL13WMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue?macro=true";
import { default as checkoutHuqjphShdWMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue?macro=true";
import { default as cookie_45deklaration2GjB4fCsZcMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue?macro=true";
import { default as glemt_45kodeordSi6EsaCcmXMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue?macro=true";
import { default as logindlT4LNk8B1KMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue?macro=true";
import { default as _91slug_934BJRPaqco9Meta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue?macro=true";
import { default as indexYaQ6ar4c8zMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue?macro=true";
import { default as nyt_45kodeordgsTfNyTdlAMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue?macro=true";
import { default as opret9lWk6iJ9fTMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue?macro=true";
import { default as prereceiptVA2nYx8a3ZMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue?macro=true";
import { default as reklamationmH2Q0Bl0UfMeta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue?macro=true";
import { default as tak_45for_45din_45bestillingvYzZ93fj50Meta } from "/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93XC5eIRL13WMeta || {},
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/[...slug].vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/checkout.vue")
  },
  {
    name: "cookie-deklaration",
    path: "/cookie-deklaration",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/cookie-deklaration.vue")
  },
  {
    name: "glemt-kodeord",
    path: "/glemt-kodeord",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/glemt-kodeord.vue")
  },
  {
    name: "logind",
    path: "/logind",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/logind.vue")
  },
  {
    name: "mitaller-slug",
    path: "/mitaller/:slug()",
    meta: _91slug_934BJRPaqco9Meta || {},
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/[slug].vue")
  },
  {
    name: "mitaller",
    path: "/mitaller",
    meta: indexYaQ6ar4c8zMeta || {},
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/mitaller/index.vue")
  },
  {
    name: "nyt-kodeord",
    path: "/nyt-kodeord",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/nyt-kodeord.vue")
  },
  {
    name: "opret",
    path: "/opret",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/opret.vue")
  },
  {
    name: "prereceipt",
    path: "/prereceipt",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/prereceipt.vue")
  },
  {
    name: "reklamation",
    path: "/reklamation",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/reklamation.vue")
  },
  {
    name: "tak-for-din-bestilling",
    path: "/tak-for-din-bestilling",
    component: () => import("/codebuild/output/src1728295859/src/codestar-connections.eu-west-1.amazonaws.com/git-http/211125639990/eu-west-1/a0cdb6d8-af71-4bfd-8623-9c0e286980a0/aller/allerservice.dk/pages/tak-for-din-bestilling.vue")
  }
]